import * as wp from './api'
import * as c from './constants'
import Immutable from 'immutable'

const LOAD_MENU = 'LOAD_WP_MENU'
const LOAD_MENU_DONE = 'LOAD_WP_MENU_DONE'
const LOAD_MENU_ERROR = 'LOAD_WP_MENU_ERROR'


const LOAD_CATEGORIES = 'LOAD_WP_CATEGORIES'
const LOAD_CATEGORIES_DONE = 'LOAD_WP_CATEGORIES_DONE'
const LOAD_CATEGORIES_ERROR = 'LOAD_WP_CATEGORIES_ERROR'

const LOAD_COUNTRY_FILES = 'LOAD_COUNTRY_FILES'
const LOAD_COUNTRY_FILES_DONE = 'LOAD_COUNTRY_FILES_DONE'
const LOAD_COUNTRY_FILES_ERROR = 'LOAD_COUNTRY_FILES_ERROR'


const LOAD_POSTS = 'LOAD_WP_OSTS'
const LOAD_POSTS_DONE = 'LOAD_WP_POSTS_DONE'
const LOAD_POSTS_ERROR = 'LOAD_WP_POSTS_ERROR'


const LOAD_POST = 'LOAD_WP_POST'
const LOAD_POST_DONE = 'LOAD_WP_POST_DONE'
const LOAD_POST_ERROR = 'LOAD_WP_POST_ERROR'


const LOAD_PAGE = 'LOAD_WP_PAGE'
const LOAD_PAGE_DONE = 'LOAD_WP_PAGE_DONE'
const LOAD_PAGE_ERROR = 'LOAD_WP_PAGE_ERROR'


const initialState = Immutable.Map()


export const getHighlights = () => (dispatch, getState) => {
    const items = getState().getIn(["map", "time"])
    if (items) {
        const time = items[0]
        const timeCategory = getState().getIn(['map', 'categories', 'items']).find(f => f.get("type") == "Time").get("items").find(i => i.get("id") == time).toJS()
        let m, y
        const monthYear = timeCategory.code.split("-")

        if (monthYear.length > 2){
            
            y = monthYear[2]
            m = monthYear[1]  //if it is a period the format will be MM-MM-YYYY where first MM is month start and second MM is month End
        }else{
            y = monthYear[1]
            m = monthYear[0]
        }


        const nextMonthDate = new Date(Date.UTC(y, m, 1))  //month is zero based on js the date is set to first day of next month

        const wpDate = new Date(nextMonthDate - 1)
        dispatch(getPosts(c.CATEGORY_HIGHLIGHTS, wpDate))

    }
}


export const getWPCountryFiles = () => (dispatch, getState) => {
    
    dispatch({type: LOAD_COUNTRY_FILES})
    wp.getWPCountryFiles().then(data => {
        dispatch({type: LOAD_COUNTRY_FILES_DONE, data})
    }).catch(error => {
        dispatch({type: LOAD_COUNTRY_FILES_ERROR, error})
    })
}


export const getWpCategories = () => (dispatch, getState) => {
    
    dispatch({type: LOAD_CATEGORIES})
    wp.getCategories().then(data => {
        dispatch({type: LOAD_CATEGORIES_DONE, data})
    }).catch(error => {
        dispatch({type: LOAD_CATEGORIES_ERROR, error})
    })
}


export const getPosts = (category, date) => (dispatch, getState) => {
    const locale = getState().getIn(['intl', 'locale'])

    dispatch({
        type: LOAD_POSTS,
        category
    })
    wp.getPosts(category, date, locale, 100, 1).then(data => {
        dispatch({
            type: LOAD_POSTS_DONE,
            data,
            category
        })
    }).catch(error => {
        dispatch({
            type: LOAD_POSTS_ERROR,
            error,
            category
        })
    })
}


/*
Gt WP Menus  (WP-REST-API V2 Menus plugin requiered)
*/
export const getMenu = (name) => (dispatch, getState) => {
    const locale = getState().getIn(['intl', 'locale'])

    dispatch({
        type: LOAD_MENU
    })
    wp.getMenu(name, locale).then(data => {
        dispatch({
            type: LOAD_MENU_DONE,
            data
        })
    }).catch(error => {
        dispatch({
            type: LOAD_MENU_ERROR,
            error
        })
    })
}

export const getPost = (slug) => (dispatch, getState) => {
    const locale = getState().getIn(['intl', 'locale'])


    dispatch({
        type: LOAD_POST,
        slug
    })
    wp.getPost(slug, locale).then(data => {
        dispatch({
            type: LOAD_POST_DONE,
            data,
            slug
        })
    }).catch(error => {
        dispatch({
            type: LOAD_POST_ERROR,
            error,
            slug
        })
    })
}


export const getPage = (slug) => (dispatch, getState) => {
    const locale = getState().getIn(['intl', 'locale'])

    dispatch({
        type: LOAD_PAGE,
        slug
    })
    wp.getPage(slug, locale).then(data => {
        dispatch({
            type: LOAD_PAGE_DONE,
            data,
            slug
        })
    }).catch(error => {
        dispatch({
            type: LOAD_PAGE_ERROR,
            error,
            slug
        })
    })
}

/*
From name and language return the id
*/
export const getOptionId = (name, lang, getState) => {
    const item = getState().getIn(['wordpress', 'options', 'items'])
        ? getState().getIn(['wordpress', 'options', 'items']).find(i => i.slug == name + '-' + lang)
        : null

    if (item) {
        return item.id
    } else return null;
}


export default (state = initialState, action) => {
    switch (action.type) {

        case LOAD_MENU: {
            return state.setIn(['menu', 'loading'], true)
        }
        case LOAD_MENU_DONE: {
            const {
                data
            } = action
            return state.setIn(['menu', 'loading'], false)
                .deleteIn(['menu', 'error'])
                .setIn(['menu', 'items'], data)
        }
        case LOAD_MENU_ERROR: {
            return state
                .setIn(['menu', 'loading'], false)
                .setIn(['menu', 'error'], action.error)
        }

        /*WP Categories*/
        case LOAD_CATEGORIES: {
            return state.setIn(['categories', 'loading'], true)
        }
        case LOAD_CATEGORIES_DONE: {
            const {
                data
            } = action
            return state.setIn(['categories', 'loading'], false)
                .deleteIn(['categories', 'error'])
                .setIn(['categories', 'items'], Immutable.fromJS(data))
        }
        case LOAD_CATEGORIES_ERROR: {
            return state
                .setIn(['categories', 'loading'], false)
                .setIn(['categories', 'error'], action.error)
        }


        /*WP Categories*/
        case LOAD_COUNTRY_FILES: {
            return state.setIn(['countryFiles', 'loading'], true)
        }
        case LOAD_COUNTRY_FILES_DONE: {
            const {
                data
            } = action
            return state.setIn(['countryFiles', 'loading'], false)
                .deleteIn(['countryFiles', 'error'])
                .setIn(['countryFiles', 'items'], Immutable.fromJS(data))
        }
        case LOAD_COUNTRY_FILES_ERROR: {
            return state
                .setIn(['countryFiles', 'loading'], false)
                .setIn(['countryFiles', 'error'], action.error)
        }

        /*WP Posts*/
        case LOAD_POSTS: {
            const {category} = action
            return state.setIn(['posts', category, 'loading'], true)
        }

        case LOAD_POSTS_DONE: {
            const {
                data,
                category
            } = action

            return state.setIn(['posts', category, 'loading'], false)
                .deleteIn(['posts', category, 'error'])
                .setIn(['posts', category, 'items'], data)
        }

        case LOAD_POSTS_ERROR: {
            return state
                .setIn(['posts', 'loading'], false)
                .setIn(['posts', 'error'], action.error)
        }


        /*WP Posts*/
        case LOAD_POST: {
            const {slug} = action
            return state.setIn(['post', slug, 'loading'], true)
        }

        case LOAD_POST_DONE: {
            const {
                data,
                slug
            } = action
            return state.setIn(['post', slug, 'loading'], false)
                .deleteIn(['post', slug, 'error'])
                .setIn(['post', slug, 'content'], Immutable.fromJS(data))
        }

        case LOAD_POST_ERROR: {
            return state
                .setIn(['post', 'loading'], false)
                .setIn(['post', 'error'], action.error)
        }

        /*WP Posts*/
        case LOAD_PAGE: {
            const {slug} = action
            return state.setIn(['page', slug, 'loading'], true)
        }

        case LOAD_PAGE_DONE: {
            const {
                data,
                slug
            } = action
            return state.setIn(['page', slug, 'loading'], false)
                .deleteIn(['page', slug, 'error'])
                .setIn(['page', slug, 'content'], Immutable.fromJS(data))
        }

        case LOAD_PAGE_ERROR: {
            return state
                .setIn(['page', 'loading'], false)
                .setIn(['page', 'error'], action.error)
        }


        default:
            return state
    }
}
