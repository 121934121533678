import React, {Component} from 'react';
import './App.scss';


import {Provider} from 'react-redux'
import {Route, Switch, Redirect,withRouter} from 'react-router' // react-router v4/v5
import {ConnectedRouter, ConnectedRoute} from 'connected-react-router/immutable'
import configureStore, {history} from './redux/store'
import {connect} from 'react-redux';
import messages_fr from "./translations/fr.json";
import messages_en from "./translations/en.json";
import { updateIntl } from 'react-intl-redux'
import {IntlProvider,injectIntl} from "react-intl";

import smoothscroll from 'smoothscroll-polyfill';
import asyncComponent from "./AsyncComponent";
import withTracker from './withTracker.jsx'
import {getWpCategories} from  './wp/module'
import ResponsiveContainer from './layout'
import Page from './wp/Page'
import PostPage from './wp/PostPage'
import Footer from './layout/Footer'
import Header from './layout/Header'
const Home = asyncComponent(() => import("./home"));



// kick off the polyfill!
smoothscroll.polyfill();

const messages = {
  'fr': messages_fr,
  'en': messages_en
};


const InjectTitle=injectIntl(({intl})=>{
  document.title=intl.formatMessage({id:'app.title'})
  return null
})

class IntlRoutes extends Component {

  constructor(props) {
     super(props);
   }

  componentDidMount(){

    const locale = this.props.location.pathname.split("/")[1]

    store.dispatch(updateIntl({locale,messages:messages[this.props.match.params.lan]}))

  }

  componentDidUpdate(){
    const locale = this.props.location.pathname.split("/")[1]

    store.dispatch(updateIntl({locale,messages:messages[this.props.match.params.lan]}))

  }

  render() {
    const self=this;
    const props = this.props;
    const locale = this.props.location.pathname.split("/")[1]

    return (<IntlProvider key={locale} locale={locale} messages={messages[locale]}>
      <div>

        <InjectTitle/>
            <Route exact={true} path="/:lan">
                  <Home/>
              </Route>

            <Route exact={true} path="/:lan/:slug/">
              <div>
                <Header internal={true}></Header>
                <Page/>
              </div>
            </Route>

            <Route exact={true} path="/:lan/posts/:slug">
              <div>
                <Header internal={true}><PostPage/></Header>
              </div>

            </Route>

          <Footer/>
      </div>

    </IntlProvider>)
  }
}





const MainRoutes = (props) => {
  return (<ConnectedRouter history={history}>
  <Switch>

      <Route path="/:lan" render={(props)=>
          <IntlRoutes {...props}/>}>
      </Route>
      <Redirect to="/en"></Redirect>

    </Switch>

  </ConnectedRouter>)
}

const store = configureStore()

class AppWrapper extends Component {

  render() {

    return (<Provider store={store}>
            <MainRoutes></MainRoutes>
          </Provider>);
  }
}

export default AppWrapper;
