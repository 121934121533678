import {post,get} from '../api/commons'
const API_ROOT = document.location.href.indexOf('localhost') > -1 ? 'http://api.localhost' : "https://api."+document.location.host
const VIFAA_DASH_API = "https://admin.africafertilizer.org/api/countries/vifaacountries"
const URL_CATEGORIES = API_ROOT + '/categories'
const URL_COUNTRIES =  'africa.topo.json'

const URL_REPORT_DATA =  API_ROOT+'/report/last';

function queryParams (params) {
  return Object.keys(params)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&')
}

export const getCategories = (params) => {
  return get(URL_CATEGORIES, params)
}

export const getFullData = (params) => {
  const ps=params?Object.keys(params).map(p=>({dimension:p,value:params[p]})):null
  return post(URL_REPORT_DATA,{params:ps})
}

export const getCountries = (params) => {
  return get(URL_COUNTRIES, params)
}

export const getVifaaDashboards = () => {
  return get(VIFAA_DASH_API)
}

