import React, { Component ,useEffect,useState} from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {getMenu} from '../wp/module'
import {Link} from "react-router-dom";
import {injectIntl} from 'react-intl';
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Label,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react'

import {withRouter} from "react-router";


const HeaderMenu=({fixed,mobile,intl, loading,items,error,onLoad})=>{
  const getLink=(o)=>{
      switch (o.type_label.toUpperCase()) {
        case 'POST':
          return `#${intl.locale}/posts/${o.slug}`;
        case 'PAGE':
          return `#${intl.locale}/${o.slug}`;
        default:
        return `#${intl.locale}${o.url}`;
      }
  }

  console.log(intl.locale)

  // De forma similar a componentDidMount y componentDidUpdate
  useEffect(() => {

      onLoad('main')

  }, [intl.locale]);

  return (
    <Menu borderless fluid className="header-menu"
      fixed={fixed ? 'top' : null}
      inverted={!fixed}
      floated={mobile?"none":"right"}
      color="black"
      vertical={mobile}>
      {items && items.items.sort((a,b) => a.menu_order-b.menu_order).filter(f=>f.url!='#pll_switcher').map(e =>
        <Menu.Item key={e.ID}>
          <a href={getLink(e)}><div dangerouslySetInnerHTML={{ __html: e.title }} /></a>
        </Menu.Item>
      )}
    </Menu>
  )
}


const mapStateToProps = state => {

  return {
    error:state.getIn(['wordpress','menu','error']),
    items:state.getIn(['wordpress','menu','items']),
    loading:state.getIn(['wordpress','menu','loading'])
  }
}

const mapActionCreators = {
  onLoad:getMenu
};

export default connect(mapStateToProps, mapActionCreators)(injectIntl((HeaderMenu)));
