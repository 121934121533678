import React, { Component } from 'react'
import NavMenu from './NavMenu'

import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";

import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react'
import PropTypes from 'prop-types'
import './layout.scss'
import AppHeader from './Header'
import HeaderNav from './Nav.jsx'
import Indicators from '../home/Indicators'
// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
  const isSSR = typeof window === 'undefined'
  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })
  render() {
    const { children , big, internal} = this.props
    const { fixed } = this.state
    return (
      <Responsive className="desktop layout" getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>

          <Visibility once={false} onBottomPassed={this.showFixedMenu} onBottomPassedReverse={this.hideFixedMenu}>
            <Container fluid>
                {internal&&<AppHeader internal={internal}/>}
                {children}
            </Container>
          </Visibility>
      </Responsive>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })
  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children, big } = this.props
    const { sidebarOpened } = this.state


    return (
      <Responsive className="mobile layout" as={Sidebar.Pushable} getWidth={getWidth} maxWidth={Responsive.onlyMobile.maxWidth}>

        <Sidebar as={Menu} animation='push' inverted onHide={this.handleSidebarHide} vertical visible={sidebarOpened}>
            <Container>

            </Container>

        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
            <Container>
            <Menu text>
              <Menu.Item onClick={this.handleToggle}> <Icon name='sidebar' color="orange" /> </Menu.Item>
            </Menu>

                {children}

          </Container>
        </Sidebar.Pusher>
      </Responsive>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ( props) => {
  const children=React.Children.map(props.children, (child =>  React.cloneElement(child,props)))
  return (
  <div>
    <DesktopContainer {...props}>{children}</DesktopContainer>
    <MobileContainer {...props}></MobileContainer>
  </div>
)}

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

export default ResponsiveContainer
