import React,{useEffect} from 'react';
import { Dropdown} from 'semantic-ui-react'
import { connect } from 'react-redux'
import {injectIntl, FormattedMessage, IntlProvider} from 'react-intl';
import { toPng} from 'html-to-image';
import download from 'downloadjs';
import he from 'he';
import {ISO3_TO_ISO2} from '../constants';
import messages from '../translations/messages';

export const FormattedMessageFixed=(props)=> {
  return <FormattedMessage {...props} />;
}


export const trend = ({confirmed, confirmedPrev,confirmedPrev14,confirmedPrev28}) => {

  const newCasesLast14=confirmed-confirmedPrev14

  const newCasesPrev14=confirmedPrev14-confirmedPrev28

  if (newCasesLast14==0 && newCasesPrev14 != 0 ){
      return 100
  }
  if (newCasesLast14==0 && newCasesPrev14 ==0 ){
      return 0
  }

  return (newCasesLast14 - newCasesPrev14) / newCasesPrev14 * 100
}

export const trend2code=(trend) => {
  if (trend === null) {
    return 'NO_DATA'
  } else {
    if (trend <= 0) {
      return 'COVID_STABLE_OR_DECREASE'
    } else if (trend < 50) {
      return 'COVID_INCREASE_OF_LESS_50'
    } else if (trend <= 100 ) {
      return 'COVID_INCREASE_OF_50100'
    } else if (trend > 100) {
      return 'COVID_INCREASE_OF_100'
    }
  }
}

export const trend2css=(trend) => {
  if (trend === null) {
    return 'active no-data'
  } else {
    if (trend <= 0) {
      return 'active  covid-stable-or-decrease'
    } else if (trend < 50) {
      return 'active  covid-increase-of-less-50'
    } else if (trend <= 100) {
      return 'active  covid-increase-of-50100'
    } else if (trend > 100) {
      return 'active  covid-increase-of-100'
    }
  }
}

export const ordinalSort=(a,b)=>(a.position-b.position)

export const code2Slug=(code)=>{
      return code.toLowerCase().replace(/__/gi,"_")
}

export const code2ClassName=(code)=>{
  return code.toLowerCase().split('_').join('-')
}
export const code2Key=(prefix,code)=>{
  console.log("check this key "+ prefix+'.'+code.toLowerCase().split('_').join('.'))
  return prefix+'.'+code.toLowerCase().split('_').join('.')
}


export const LocaleSpan = injectIntl((props) => {
  const {translations, intl, className} = props
  return (
    <span {...props}>{getLocaleText(translations, intl.locale)}</span>
  )
})


export const LocaleSpanWithDisclaimer = (props) => {
  const {translations, intl, className} = props
  const splittedText = getLocaleText(translations, intl.locale).split('\n');
  return (
    <div>
      <span>{splittedText.map(t => <span>{t}<br/></span>)}</span>
      {translations.machine && translations.machine.indexOf(intl.locale.toUpperCase()) !== -1 &&
      <div className="translation-disclaimer">{`(${intl.formatMessage(messages.translationsDisclaimer)})`}</div>}
    </div>
  )
}


export const getLocaleText = (translations, locale) => {
  const tr =translations? translations[locale.toUpperCase()]:null;
  if (tr) {
    return he.decode(tr)
  }else{
    return null;
  }
}


export const applyFilters=(d,region,country)=>{
      let include=!region && ! country
      if (region){
        include = include || d.country.parent.id==region;
      }

      if (country){
        include=include || d.country.id==country;
      }



    return include;

}



const getItems = (categories, type) => {
  if (categories){
    const items = categories.find(i => i.get('type') == type);
    return items ? items.get('items').toJS() : null;
  }
  return [];
}

const toOptions = (items, locale, category) => items ? items.sort((a,b) => a.position - b.position).map(i => ({
    key: i.id,
    value: i.id,
    text:  getLocaleText(i.labels, locale)?  getLocaleText(i.labels, locale) : i.value,
    flag: ISO3_TO_ISO2[i.code],
    category
  })) : []




const connected = (fn) => {

  const mapStateToProps = (state, ownProps) => {
    return {
      categories: state.getIn(['map','categories','items']),
    }
  }

  const mapActionCreators = {};

  return  connect(mapStateToProps, mapActionCreators)(injectIntl(fn))
}





export const DropDownFilter = connected(({categories, type, options, placeholder, selected, icon='group', onChange, locale}) => {

  return  <Dropdown
    placeholder={placeholder}
    onChange={onChange}
    fluid
    multiple
    search
    selection
    options={toOptions(getItems(categories, type),locale)}
  >

  </Dropdown>
})


export const CustomCountryDropDown = connected(({categories, type, options, placeholder, selected, icon='group', onChange, locale}) => {

  const regions = toOptions(getItems(categories, 'Region'), locale, 'Region');
  const countries = toOptions(getItems(categories, 'Country'), locale, 'Country');

  return  <Dropdown

    placeholder={placeholder}
    fluid
    onChange={onChange}
    search
    selection
    //className='icon'
    clearable
    options={regions.concat(countries)}
  >

  </Dropdown>
})


export const SingleDropDown = connected(({categories, type, options, placeholder, selected, icon='group', onChange,locale,className}) => {
  return  <Dropdown
    fluid
    selection
    placeholder={placeholder}
    onChange={onChange}
      className={className}
  value={selected[0]}
    options={options?options:toOptions(getItems(categories, type),locale)}>

  </Dropdown>
})




export const PngExport=({id, name="chart", filters=[],includes=[]})=>{


  const changedNodes=[]
      return (<i aria-hidden="true" class="image large icon" onClick={e=>{

        var node = document.getElementById(id);

        var exportable=id?node.getElementsByClassName("png exportable")[0]:node

        const doFilter=(node)=>{

          if (node.classList && ([...node.classList].map(l=>filters.indexOf(l) > -1).filter(n=>n).length > 0 ) && !([...node.classList].map(l=>includes.indexOf(l) > -1).filter(n=>n).length > 0 ) ){
            return false
          }
          return true
        }


      toPng(exportable, {backgroundColor:"#FFF",filter:doFilter, style:{'border':'0px !important'}})
          .then(function (dataUrl) {
            download(dataUrl, name+'.png');

          })
          .catch(function (error) {
            console.error('oops, something went wrong!', error);
          });


      }}></i>)
}
