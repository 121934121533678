import React, { Component ,useEffect,useState} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {injectIntl} from 'react-intl';
import {getPost} from './module'
import Post from './SinglePost'
import {
  Container,
  Segment
} from 'semantic-ui-react'

import './wp.scss'
const PostPage=(props)=>{
  const {onLoad,loading,post,location}=props
  const slug=props.match.params.slug
  if(post){

  }
  useEffect(() => {
    onLoad(slug)
 },[]);

    return <Post post={post}/>
}



const mapStateToProps = (state,ownProps) => {

  const slug=ownProps.match.params.slug
  return {
    error:state.getIn(['wordpress','post',slug,'error']),
    post:state.getIn(['wordpress','post',slug,'content',0])?state.getIn(['wordpress','post',slug,'content',0]).toJS():null,
    loading:state.getIn(['wordpress','post',slug,'loading'])
  }
}

const mapActionCreators = {
  onLoad:getPost
};

export default connect(mapStateToProps, mapActionCreators)(injectIntl(PostPage));
