import React, {
  useState,
  useEffect
} from 'react';
import {Popup, Icon, Segment} from 'semantic-ui-react'
import {getLocaleText, LocaleSpan, code2ClassName} from '../components/Components'
import {FormattedMessage, FormattedDate} from 'react-intl'
import {IntlProvider, injectIntl} from 'react-intl';

const CovidDescriptionPopUp = injectIntl((props) => {

  return(
  	<Popup content={
        <span {...props}><FormattedMessage id="indicators.covid.tooltip" defaultMessage="% change, calculated automatically once per day using the current count of cases, that is, the number of new diagnoses in the past 14 days compared to new cases in the 14 days prior"/></span>
      } trigger={props.children}/>
	)
})

export default CovidDescriptionPopUp;
