import React, {
  useState,
  useEffect
} from 'react';
import {Popup, Icon, Segment} from 'semantic-ui-react'
import {getLocaleText, LocaleSpan, code2ClassName} from '../components/Components'
import {FormattedMessage, FormattedDate} from 'react-intl'
import {IntlProvider} from 'react-intl';

const IndicatorDescriptionPopUp = ({indicator, intl, showTitle, children}) => {

  return(

  		<Popup   content={
          <div className="indicator-description">
            {showTitle && <div className="title text">
                <LocaleSpan translations={indicator.labels}/></div>}
                <LocaleSpan translations={indicator.descriptions}/>
          </div>
        } trigger={children}/>

	)
}

export default IndicatorDescriptionPopUp;
