import React, { Component ,useEffect,useState} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {injectIntl} from 'react-intl';
import {getPage} from './module'
import {withRouter} from 'react-router' // react-router v4/v5
import withTracker from "../withTracker"

import {
  Container,
  Segment
} from 'semantic-ui-react'

const Post=(props)=>{
  const {onLoad,loading,post,location}=props
  const slug=props.match.params.slug

  useEffect(() => {
    onLoad(slug)
 },[slug]);

    return <Container className="wp page">
            <h1>{post&&post.title.rendered}</h1>
            {post&&<div dangerouslySetInnerHTML={{__html: post.content.rendered}} />}
          </Container>
}

const mapStateToProps = (state,ownProps) => {

  const slug=ownProps.match.params.slug
  return {
    error:state.getIn(['wordpress','page',slug,'error']),
    post:state.getIn(['wordpress','page',slug,'content',0])?state.getIn(['wordpress','page',slug,'content',0]).toJS():null,
    loading:state.getIn(['wordpress','page',slug,'loading'])
  }
}

const mapActionCreators = {
  onLoad:getPage
};

export default injectIntl(withRouter(connect(mapStateToProps, mapActionCreators)(withTracker(Post))));
