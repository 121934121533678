import {
  defineMessages
} from 'react-intl'

const messages = defineMessages({

  appTitle: {id: "app.title", defaultMessage: "COVID-19 Africa Fertilizer Watch"},
  appSubTitle: {id: "app.subTitle", defaultMessage: "The impact of COVID-19 on the African fertilizer market."},
  countryHighlights: {id: "panel.country.highlight", defaultMessage: "Country Highlights"},
  regionalHighLights: {id: "panel.regional.highlight", defaultMessage: "Regional Highlights"},
  locationAllCountries: {id: 'dropdowns.locations.allCountries', defaultMessage: 'All Countries'},

  legendTitleCovid: {id: 'legend.covid.trend', defaultMessage: 'COVID Trend'},
  legendCovidLow: {id: 'map.legend.covid.low', defaultMessage: 'Stable or decrease'},
  legendCovidLimited: {id: 'map.legend.covid.limited', defaultMessage: 'increase of 25%'},
  legendCovidModerate: {id: 'map.legend.covid.moderate', defaultMessage: 'increase of 25%-50%'},
  legendCovidStrong: {id: 'map.legend.covid.strong', defaultMessage: 'increase of >50%'},
  noData: {id: 'map.label.noData', defaultMessage: 'No data'},

  briefTrendLabel: {id: "brief.trend.label", defaultMessage: "COVID-19 Trend: "},
  briefTrendNote: {id: "brief.trend.note", defaultMessage: "(% change in new cases compared to prior 2-week period)"},

  briefTrendConfirmed: {id: "brief.trend.confirmed", defaultMessage: "Number of confirmed cases as of {date}: "},
  briefTrendNewCases: {id: "brief.trend.cases", defaultMessage: "Number of new cases, {date1} to {date2}: "},



  indicatorCovid: {id: 'indicators.covid', defaultMessage: 'COVID Trend'},
  indicatorNoComments: {id: "indicators.noComments", defaultMessage: "No comments."},

  translationsDisclaimer: {id: 'translations.disclaimer', defaultMessage: 'This comment was automatically translated and may contain errors.'},
  legendOverallTitle: {id: 'legend.overall.impact', defaultMessage: 'Impact on Overall Impact'},
  legendHealthMeasureTitle: {id: 'legend.health.measures', defaultMessage: 'Impact on Health Measures'},
  legendEconomicTitle: {id: 'legend.economic.measures', defaultMessage: 'Impact on Economic Measures'},
  legendPortTitle: {id: 'legend.port.operations', defaultMessage: 'Impact on Port Operations'},
  legendTrasportTitle: {id: 'legend.domestic.transport', defaultMessage: 'Impact on Domestic Transport'},
  legendTransitTitle: {id: 'legend.transit.borders', defaultMessage: 'Impact on Transit & Borders'},
  legendInputAcessTitle: {id: 'legend.agro.input.access', defaultMessage: 'Impact on Agro-Input Access'},
  legendPriceTitle: {id: 'legend.fertilizer.price', defaultMessage: 'Impact on Fertilizer Price'},
  legendSectorResponsesTitle: {id: 'legend.sector.responses', defaultMessage: 'Impact on Fertilizer Sector Response'},
  legendStockTitle: {id: 'legend.stock.availability', defaultMessage: 'Impact on Availability of Fertilizer Stock"'},

  footerPartners: {id: "footer.partners", defaultMessage: "Partners"},
  footerContact: {id: "footer.contact", defaultMessage: "Contact Us"},
  footerDevelopedBy: {id: "footer.developedBy", defaultMessage: "Developed by"}
})

export default messages
