import React, {
  useState,
  useEffect,
  createRef
} from 'react';
import { connect } from 'react-redux'
import {Popup, Grid, Header} from 'semantic-ui-react'
import {getLocaleText} from '../components/Components'
import {setCountry, setRegion} from '../home/module'
import {injectIntl} from 'react-intl'
import {withRouter} from "react-router";
import messages from '../translations/messages';
import './locationSelector.scss';


const sortByCountryName = (locale) => {
  return function(c1, c2) {
    const c1Label = getLocaleText(c1.labels, locale)
    const c2Label = getLocaleText(c2.labels, locale)
    return c1Label.localeCompare(c2Label)
  }
}

const LocationSelector = ({data, country, region, onChangeCountry, onChangeRegion, intl, match, categories}) => {

  const [expanded, setExpanded] = useState(false);
  const regions = categories ? categories.find(i => i.get('type') === 'Region').get('items') : [];
  const countries = categories ? categories.find(i => i.get('type') === 'Country').get('items') : [];
  const countriesWithData = data ? countries.filter(c => data.find(d => d.country.id === c.getIn(['id']))) : [];
  let regionSelected = null;
  let countrySelected = null;
  if (regions && region) {
    regionSelected = regions.find(r => r.get('id') === region);
  } else if (countries && country) {
    countrySelected = countries.find(r => r.get('id') === country);
  }

  useEffect(() => {
    if (!countriesWithData.find(c => c.getIn(['id']) === country)) {
      onChangeCountry(null);
      onChangeRegion(null);
    }
  }, [data])

  LocationSelector.handleClickOutside = () => setExpanded(false);

  return (
    <Popup on='click' className="location-dropdown" trigger={
        <div className={`location-selected ${expanded ? 'expanded' : ''}`}>
          {regionSelected && <div className="selected-label">{getLocaleText(regionSelected.get('labels').toJS(), intl.locale)}</div>}
          {countrySelected && <div className="selected-label">{getLocaleText(countrySelected.get('labels').toJS(), intl.locale)}</div>}
          {!countrySelected && !regionSelected && <div className="selected-label">{intl.formatMessage(messages.locationAllCountries)}</div>}
          {!countrySelected && !regionSelected && <i className="dropdown icon"/>}
          {(countrySelected || regionSelected) && <i className="close icon" onClick={e => {onChangeCountry(null); onChangeRegion(null); e.stopPropagation()}}/>}
        </div>
      } flowing hoverable>
      <Grid  className="locations-list" centered divided columns={3}>
        {regions.map(region => {
          const regionCountries = countriesWithData.filter(c => c.getIn(['parent', 'id']) === region.get('id'));
          return (
            <Grid.Column key={`region-${region.get("id")}`} textAlign='left'>
              <Header as='h4' className="region-item" onClick={e => {
              onChangeRegion(region.get('id'));
              }}>{getLocaleText(region.get('labels').toJS(), intl.locale)}</Header>
              {regionCountries.size && regionCountries.toJS().sort(sortByCountryName(intl.locale)).map(country => {
                return (
                  <p key={`country-${country.code}`} className="country-item" onClick={e => {

                      onChangeCountry(country.id);

                      }}>

                    {getLocaleText(country.labels, intl.locale)}
                  </p>
                )
              })}
            </Grid.Column>
          )
        })}
      </Grid>
    </Popup>
  )
}

const mapStateToProps = state => {
  return {
    country: state.getIn(['map','country']),
    region: state.getIn(['map','region']),
    categories: state.getIn(['map','categories','items']),
  }
}

const mapActionCreators = {
  onChangeCountry: setCountry,
  onChangeRegion: setRegion,
};

export default connect(mapStateToProps, mapActionCreators)(injectIntl(withRouter(LocationSelector)));
