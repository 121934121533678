import {
  combineReducers
} from 'redux-immutable';

import {
  connectRouter
} from 'connected-react-router/immutable'

import { Provider, intlReducer } from 'react-intl-redux'

import wordpress from '../wp/module'
import map from '../home/module'
const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  wordpress,
  map,
 intl: intlReducer

})




export default createRootReducer
