import React, {
  useState,
  useEffect,
  createRef
} from 'react';
import { connect } from 'react-redux'
import {Button, Popup} from 'semantic-ui-react'
import {withRouter} from "react-router";
import {injectIntl, FormattedMessage} from 'react-intl';


const LangSelector = (props)=>{

  const [internalLocale, setInternalLocale] = useState();
  useEffect(() => setInternalLocale(props.intl.locale),[])

  const changeURL = (e) => {
    if (e != internalLocale) {
      setInternalLocale(e)

      const newPath=(props.intl.locale =='fr')? '/en' + props.location.pathname.substr(3): '/fr' + props.location.pathname.substr(3)
        window.setTimeout(()=>props.history.push(newPath),100)
    }
  }

  return (

    <Popup position='top right' 
      content={
        <FormattedMessage id="translations.swticher.tooltip" defaultMessage="Switch site language between English and French here. Note that Google Translate was used to automatically translate certain content and the translations may contain errors."/>} 
      trigger={
        <Button.Group className="lang-selector">
          <Button className={internalLocale === 'en' ? 'selected' : ''} onClick={e=>changeURL('en')}>EN</Button>
          <Button className={internalLocale === 'fr' ? 'selected' : ''} onClick={e=>changeURL('fr')}>FR</Button>
        </Button.Group>
    }/>
    
  )
}



const mapStateToProps = state => {
  return {
  }
}

const mapActionCreators = {};

export default connect(mapStateToProps, mapActionCreators)(injectIntl(withRouter(LangSelector)));
