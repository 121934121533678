import React, {
    useState,
    useEffect,
    createRef
} from 'react';
import {connect} from 'react-redux'
import {Button, Grid, Segment, Icon, Popup} from 'semantic-ui-react'
import {getLocaleText, FormattedMessageFixed, code2Key} from '../components/Components'
import {setMeasure} from './module'
import messages from '../translations/messages'
import {injectIntl, FormattedMessage} from 'react-intl';
import IndicatorDescriptionPopUp from './IndicatorDescriptionPopup'
import CovidDescriptionPopUp from './CovidDescriptionPopup'
import './indicators.scss';


const BtN = ({i, measure, intl, onChangeMeasure}) => {
    const content = (<div><p>{getLocaleText(i.descriptions, intl.locale)}</p></div>)
    return (
        <IndicatorDescriptionPopUp indicator={i} intl={intl}>
            <div className={`indicator-button ${measure === i.code ? 'active' : ''}`}
                 onClick={e => onChangeMeasure(i.code)}>
                <Icon className={`indicator-icon ${i.code.toLowerCase()}`}/>
                <div className={`indicator-label ${i.code.toLowerCase()}`}>
                    <FormattedMessageFixed id={code2Key("indicators", i.code)}
                                           defaultMessage={getLocaleText(i.labels, intl.locale)}/>
                </div>
            </div>
        </IndicatorDescriptionPopUp>
    )
}

const Indicators = ({categories, measure, onChangeMeasure, intl}) => {


    const indicatorGroups = categories ? categories.find(c => c.get('type') === 'Group').get("items").toJS() : [];
    const statusIndicator = categories ? categories.find(c => c.get('type') === 'Indicator').get('items').toJS().find(i => i.code === 'OVERALL_IMPACT') : null;


    const indicators = categories ? categories.find(c => c.get('type') === 'Indicator').get('items').toJS():null



    //group1 && group1.map(i =><)
    return (
        <div className="indicators">

            {indicatorGroups && indicatorGroups.map(g => (
                <div className="group">
                    <div className="header">{getLocaleText(g.labels, intl.locale)}</div>
                    <div className="list">
                    {indicators && indicators.filter(i => i.parent && i.parent.code === g.code).map(i => (

                            <BtN i={i} measure={measure} intl={intl} onChangeMeasure={onChangeMeasure}/>
                        )
                    )}
                    </div>
                </div>
            ))}

        </div>


    )
}


const mapStateToProps = state => {
    return {
        measure: state.getIn(['map', 'measure']),
        categories: state.getIn(['map', 'categories', 'items']),
    }
}

const mapActionCreators = {
    onChangeMeasure: setMeasure,
};

export default connect(mapStateToProps, mapActionCreators)(injectIntl(Indicators));
