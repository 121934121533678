
export const COLORS = [
	{key: 'LOW_OR_NONE', color: '#95c46c'}, 
	{key: 'LIMITED', color: '#fbdf42'}, 
	{key: 'MODERATE', color: '#f5a05b'}, 
	{key: 'STRONG', color: '#e46868'}, 

	{key: 'STABLE_OR_DECREASE', color: '#95c46c'},

	{key: 'INCREASE_OF_25', color: '#fbdf42'},
	{key: 'INCREASE_OF_2550', color: '#f5a05b'}, 
	{key: 'INCREASE_OF_50', color: '#e46868'},


	{key: 'FOOD_CROP', color: '#95c46c'},
	{key: 'FOOD__CASH_CROP', color: '#f5a05b'},
	{key: 'CASH_CROP', color: '#e46868'},



	{key: 'NO_DATA', color: '#CCC'},
	{key: 'NO_DATA_A', color: '#CCC'}
];

export const COLORS_REVERSED = [
	{key: 'LOW_OR_NONE', color: '#e46868'}, 
	{key: 'LIMITED', color: '#f5a05b'}, 
	{key: 'MODERATE', color: '#fbdf42'}, 
	{key: 'STRONG', color: '#95c46c'}, 
	{key: 'NO_DATA', color: '#CCC'}
];

export const ISO3_TO_ISO2 = {
	BDI: 'bi',
	ETH: 'et',
	KEN: 'ke',
	UGA: 'ug',
	RWA: 'rw',
	TZA: 'tz',
	MWI: 'mw',
	MOZ: 'mz',
	ZAF: 'za',
	ZMB: 'zm',
	ZWE: 'zw',
	BEN: 'bj',
	BFA: 'bf',
	CPV: 'cv',
	TCD: 'td',
	CIV: 'ci',
	GMB: 'gm',
	GHA: 'gh',
	GIN: 'gn',
	GNB: 'gw',
	LBR: 'ls',
	MRT: 'mr',
	MLI: 'ml',
	NER: 'ne',
	NGA: 'ng',
	SEN: 'sn',
	SLE: 'sl',
	TGO: 'tg'
}