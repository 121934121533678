import React from 'react'
import {
  Container,
  Segment
} from 'semantic-ui-react'

const Post=({post, visibility={'title':true,'content':true}})=>{

    return <Container>
      {post&&visibility['title']&&<h2 dangerouslySetInnerHTML={{__html: post.title.rendered}} />}
      {post&&visibility['content']&&<div dangerouslySetInnerHTML={{__html: post.content.rendered}} />}</Container>
}

export default  Post
