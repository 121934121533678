import {get} from '../api/commons.js'

const API_ROOT = document.location.href.indexOf('localhost') > -1 ? 'https://wp.marketwatch.dgstg.org/wp-json' : "https://wp." + document.location.host + '/wp-json'
const URL_MENU = API_ROOT + '/menus/v1/menus/'
const URL_CATEGORIES = API_ROOT + '/wp/v2/region?per_page=100'


const URL_MLO_CATEGORIES = API_ROOT + '/wp/v2/mlo-category?per_page=100'

const URL_POSTS = API_ROOT + '/wp/v2/posts?category_name='
const URL_POST = API_ROOT + '/wp/v2/posts?slug='
const URL_PAGE = API_ROOT + '/wp/v2/pages?slug='


export const getWPCountryFiles = () => {

    return new Promise((resolve, reject) => {
        return get(URL_MLO_CATEGORIES).then(data => {
            
            const factSheetsCategory = data.filter(d => d.slug == "fact-sheets")
            const id = factSheetsCategory.length > 0 ? factSheetsCategory[0].id : null
            if (!id) {
                resolve([])
            } else
                get(`${API_ROOT}/wp/v2/media?mlo-category=${id}&per_page=50`).then(data => {
                    
                    resolve(data)
                }).catch(function (err) {
                    reject(err)
                })
        }).catch(function (err) {
            reject(err)
        })
    })
}

export const getCategories = (name) => {
    return get(URL_CATEGORIES)
}


export const getMenu = (name, lang) => {
    return get(URL_MENU + name + '?lang=' + lang)
}

export const getPosts = (category, date, lang, perPage, page) => {

    return get(URL_POSTS
        + category
        + '&lang=' + lang
        //+ (date ? "&before=" + date.toISOString() : "")
        + (perPage ? '&per_page=' + perPage : '')
        + (page ? '&page=' + page : ''))
}


export const getPost = (slug, lang) => {

    return get(URL_POST + slug + '&lang=' + lang)
}

export const getPage = (slug, lang) => {
    return get(URL_PAGE + slug + '&lang=' + lang)
}
