import * as api from '../api'
import {getHighlights} from '../wp/module'

import Immutable from 'immutable'

const LOAD_CATEGORIES = 'LOAD_CATEGORIES'
const LOAD_CATEGORIES_DONE = 'LOAD_CATEGORIES_DONE'
const LOAD_CATEGORIES_ERROR = 'LOAD_CATEGORIES_ERROR'

const LOAD_COUNTRIES = 'LOAD_COUNTRIES'
const LOAD_COUNTRIES_DONE = 'LOAD_COUNTRIES_DONE'
const LOAD_COUNTRIES_ERROR= 'LOAD_COUNTRIES_ERROR'


const LOAD_FULL_DATA = 'LOAD_COUNTRIES'
const LOAD_FULL_DATA_DONE = 'LOAD_FULL_DATA_DONE'
const LOAD_FULL_DATA_ERROR= 'LOAD_FULL_DATA_ERROR'

const LOAD_IMPACT_DATA = 'LOAD_IMPACT_DATA'
const LOAD_IMPACT_DATA_DONE = 'LOAD_IMPACT_DATA_DONE'
const LOAD_IMPACT_DATA_ERROR= 'LOAD_IMPACT_DATA_ERROR'

const LOAD_VIFAA_DASHBOARDS = 'LOAD_VIFAA_DASHBOARDS'
const LOAD_VIFAA_DASHBOARDS_DONE = 'LOAD_VIFAA_DASHBOARDS_DONE'
const LOAD_VIFAA_DASHBOARDS_ERROR = 'LOAD_VIFAA_DASHBOARDS_ERROR'

const SET_FILTER = 'SET_FILTER'
const SET_MEASURE = 'SET_MEASURE'
const SET_COUNTRY = 'SET_COUNTRY'
const SET_REGION = 'SET_REGION'
const SET_TIME = 'SET_TIME'

const RESET_FILTER = 'RESET_FILTER'

const initialState = Immutable.Map({measure: 'OVERALL_IMPACT'})


export const setTime = (time) => (dispatch, getState) => {
  dispatch({type: SET_TIME, time})
  dispatch(getFullData())
}

export const setMeasure = (measure) => (dispatch, getState) => {

  dispatch({type: SET_MEASURE, measure})
}

export const setCountry = (country) => (dispatch, getState) => {

  dispatch({type: SET_COUNTRY, country})
}

export const setRegion = (region) => (dispatch, getState) => {
  dispatch({type: SET_REGION, region})
}

export const getCategories = () => (dispatch, getState) => {

  dispatch({type: LOAD_CATEGORIES})

  api.getCategories().then(data => {
    dispatch({
      type: LOAD_CATEGORIES_DONE,
      data
    })
  }).catch(error => {
    dispatch({
      type: LOAD_CATEGORIES_ERROR,
      error
    })
  })
}

export const getCountries = () => (dispatch, getState) => {
  dispatch({type: LOAD_COUNTRIES})
  api.getCountries().then(data => {
    dispatch({
      type: LOAD_COUNTRIES_DONE,
      data
    })
  }).catch(error => {
    dispatch({
      type: LOAD_COUNTRIES_ERROR,
      error
    })
  })
}

export const getVifaaDashboards = () => (dispatch, getState) => {
  dispatch({type: LOAD_VIFAA_DASHBOARDS})
  api.getVifaaDashboards().then(data => {
    dispatch({
      type: LOAD_VIFAA_DASHBOARDS_DONE,
      data
    })
  }).catch(error => {
    dispatch({
      type: LOAD_VIFAA_DASHBOARDS_ERROR,
      error
    })
  })
}

export const getFullData = () => (dispatch, getState) => {

  const params={'time':getState().getIn(["map","time"])}

  dispatch({type: LOAD_FULL_DATA})
  api.getFullData(params).then(data => {
    dispatch({
      type: LOAD_FULL_DATA_DONE,
      data
    })
  }).catch(error => {
    dispatch({
      type: LOAD_FULL_DATA_ERROR,
      error
    })
  })
}


export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CATEGORIES: {
      return state.setIn(['categories', 'loading'], true)
    }

    case SET_MEASURE: {
      return state.setIn(['measure'], action.measure)
    }
    case SET_REGION: {
      return state.deleteIn(['country']).setIn(['region'], action.region)
    }
    case SET_COUNTRY: {
        return state.deleteIn(['region']).setIn(['country'], action.country)
    }
    case SET_TIME: {

      return state.setIn(['time'], action.time)
    }
    case LOAD_CATEGORIES_DONE: {
      const {
        data
      } = action

      return state
        .setIn(['categories', 'loading'], false)
        .deleteIn(['categories', 'status'])
        .setIn(['categories', 'items'], Immutable.fromJS(data))
    }

    case LOAD_CATEGORIES_ERROR: {
      return state
        .setIn(['categories', 'loading'], false)
        .setIn(['categories', 'status', 'error'], action.error)
    }

    case LOAD_VIFAA_DASHBOARDS: {
      return state.setIn(['dashboards', 'loading'], true)
    }
    case LOAD_VIFAA_DASHBOARDS_DONE: {
      const {data} = action
      debugger
      return state
        .setIn(['dashboards', 'loading'], false)
        .deleteIn(['dashboards', 'status'])
        .setIn(['dashboards', 'items'], Immutable.fromJS(data))
    }

    case LOAD_VIFAA_DASHBOARDS_ERROR: {
      return state
        .setIn(['dashboards', 'loading'], false)
        .setIn(['dashboards', 'status', 'error'], action.error)
    }

//------------------------//

      case LOAD_COUNTRIES: {
        return state.setIn(['countries', 'loading'], true)
      }

      case LOAD_COUNTRIES_DONE: {
        const {
          data
        } = action


        return state
          .setIn(['countries', 'loading'], false)
          .deleteIn(['countries', 'status'])
          .setIn(['countries', 'items'], data)
      }

      case LOAD_COUNTRIES_ERROR: {
        return state
          .setIn(['countries', 'loading'], false)
          .setIn(['countries', 'status', 'error'], action.error)
      }
      //------------------------//

      case LOAD_FULL_DATA: {
        return state.setIn(['data', 'loading'], true)
      }

      case LOAD_FULL_DATA_DONE: {
        const {
          data
        } = action

        return state
          .setIn(['data', 'loading'], false)
          .deleteIn(['data', 'status'])
          .setIn(['data', 'items'], data)
      }

      case LOAD_FULL_DATA_ERROR: {
        return state
          .setIn(['data', 'loading'], false)
          .setIn(['data', 'status', 'error'], action.error)
      }
      //------------------------//

    default:
      return state
  }
}
