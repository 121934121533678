import React, {
  useState,
  useEffect,
  createRef
} from 'react';
import { connect } from 'react-redux';
import LangSelector from '../components/LangSelector';
import DateFilter from '../components/DateFilter';
import CountryFilter from '../components/LocationSelector';

import WPMenu from './NavMenu';


import {Menu,Visibility,Dropdown,Container,Image} from 'semantic-ui-react';
import {injectIntl, FormattedMessage} from 'react-intl';
import './header.scss';

const menuStyle = {
  border: 'none',
  borderRadius: 0,
  boxShadow: 'none',
  transition: 'box-shadow 0.5s ease, padding 0.5s ease',
}

const fixedMenuStyle = {
    border: '1px solid #ddd',
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
}


const indicatorFixedStyles={
    top:"66px"
}


const Header = ({data, intl, internal})=>{

  const [menuFixed,setMenuFixed] = useState(false)

  const stickTopMenu=()=>{
      setMenuFixed(true);
  }
  const unStickTopMenu=()=>{
    setMenuFixed(false)
  }

  return (
    <Menu  fluid  className="head" >
      <Container fluid>
          <Menu.Item className="logo">
            <a href="https://africafertilizer.org/" target="_blank" rel="noopener noreferrer"><img src='/logo2.png' title="AfricaFertilizer.org"/></a>
          </Menu.Item>
          <Menu.Item  className="title">

            <div className="app-title">
              <FormattedMessage id="app.title" defaultMessage="Africa Fertilizer Watch"/>
            </div>
            <div className="app-sub-title">
              <FormattedMessage id="app.subTitle" defaultMessage="Monitoring and Early Warning System"/>
            </div>
          </Menu.Item>
          {internal}
             {!internal&&
            <Menu.Item className="date">
              <DateFilter/>
            </Menu.Item>}

          {!internal&&
            <Menu.Item className="country">
              <CountryFilter data={data}/>
            </Menu.Item>
        }

          <Menu.Item className="wp">
            <WPMenu/>
          </Menu.Item>
          <Menu.Item className="lan">
            <LangSelector/>
          </Menu.Item>
      </Container>
      </Menu>


  )
}



const mapStateToProps = state => {
  return {}
}

const mapActionCreators = {};

export default connect(mapStateToProps, mapActionCreators)(injectIntl(Header));
