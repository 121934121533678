import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux'
import {SingleDropDown} from '../components/Components'
import {setTime, setMeasure, setCountry} from '../home/module'
import {injectIntl} from 'react-intl'
import {withRouter} from "react-router";
import { useParams } from "react-router-dom";

const getItemsJs = (type, categories) => {

    const cat = categories.find(q => q.get('type').toLowerCase() == type.toLowerCase());
    if (cat) {
        return cat.get('items').toJS()
    } else {
        return null
    }
}

const sortTime = (a, b) => {


    const aYearPosition = (a.value.split("-").length > 2)?2:1
    const bYearPosition = (b.value.split("-").length > 2)?2:1

    const m = a.value.split("-")[0] //m
    const y = a.value.split("-")[aYearPosition] //Y
    var dateA = new Date(m + "/01/" + y)

    const m2 = b.value.split("-")[0]
    const y2 = b.value.split("-")[bYearPosition]
    var dateB = new Date(m2 + "/01/" + y2)


    return dateB - dateA;
}



const timeOptions = (items, locale) => {

    return items ? items.map(i => {

        const parts = i.value.split("-");
        let m, m2, y

        if (parts.length > 2) {
            m = i.value.split("-")[0]
            m2 = i.value.split("-")[1]
            y = i.value.split("-")[2]
        } else {
            m = i.value.split("-")[0]
            y = i.value.split("-")[1]
        }

        var objDate = new Date(m + "/01/" + y),
            month = objDate.toLocaleString(locale, {month: m2?"short":"long"});

        var objDate2 = new Date(m2 + "/01/" + y),
            month2 = objDate2.toLocaleString(locale, {month: "short"});

        if (m2) {
            return {key: i.id, value: i.id, text: month + ' - ' + month2 + ' ' + y}
        } else {
            return {key: i.id, value: i.id, text: month + ' ' + y}
        }
    }) : []
}


const Filters = ({country, region, time, onChangeCountry, onChangeRegion, onChangeTime, intl, match, categories, onChangeMeasure}) => {

    let test = 'test'
    const ppp = useParams()
    const [options, setOptions] = useState([])
    const params = new URLSearchParams(window.location.hash.split('?')[1] || '')

    useEffect(() => {

        if (categories && match.params.country) {

            const matchRegions = categories.filter(p => p.get('type') == 'Region')
                .get(0)
                .get('items')
                .toJS()
                .filter(c => c.labels.reduce((a, c) => a.text + ',' + c.text).toLowerCase().split(',').indexOf(match.params.country.toLowerCase()) > -1)
            if (matchRegions && matchRegions.length > 0) {
                onChangeRegion(matchRegions[0].id)
            }

            const matchCountries = categories.filter(p => p.get('type') == 'Country')
                .get(0)
                .get('items')
                .toJS()
                .filter(c => {
                    return c.labels.reduce((a, c) => a.text + ',' + c.text).toLowerCase().split(',').indexOf(match.params.country.toLowerCase()) > -1
                })


            if (matchCountries && matchCountries.length > 0) {
                onChangeCountry(matchCountries[0].id)
            }
        }
    }, [match.params.country, categories])


    useEffect(() => {
        if (categories) {

            const items = getItemsJs("Time", categories)
            if (items) {
                items.sort(sortTime)
                setOptions(timeOptions(items, intl.locale))
                if (!time) {
                    if (params.get('c')) {
                        const countryId = categories.toJS().find(c => c.type === 'Country').items.find(i => i.code === params.get('c')).id
                        onChangeCountry(countryId)
                    }
                    if (params.get('i')) {
                        const indicatorCode = categories.toJS().find(c => c.type === 'Indicator').items.find(i => i.id === parseInt(params.get('i'))).code
                        onChangeMeasure(indicatorCode)
                    }
                    if (params.get('t')) {
                        onChangeTime([parseInt(params.get('t'))])
                    } else {
                        onChangeTime([items[0].id])
                    }
                }

            }
        }
    }, [categories])
    /*---Country URL navigator --*/
    if (options && time) {
        return <SingleDropDown className="time" type="Time" options={options} locale={intl.locale} selected={time}
                               onChange={(e, filter) => {
                                   onChangeTime([filter.value])
                               }}/>
    } else {
        return null
    }
}


const mapStateToProps = state => {
    return {
        country: state.getIn(['map', 'country']),
        region: state.getIn(['map', 'region']),
        time: state.getIn(['map', 'time']),
        categories: state.getIn(['map', 'categories', 'items']),
    }
}

const mapActionCreators = {
    onChangeTime: setTime,
    onChangeMeasure: setMeasure,
    onChangeCountry: setCountry
};

export default connect(mapStateToProps, mapActionCreators)(injectIntl(withRouter(Filters)));
